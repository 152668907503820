/*!
* Version: 1.1.0
* Template: Example Project
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/

// Configuration
@import "./bootstrap/functions";
// Variables
@import "./hope-ui-design-system/variable";
@import "./hope-ui-design-system/variables/index";
@import "./bootstrap/variables";

@import "./bootstrap/mixins";

// Hope Ui Design System Mixin And Helper
@import "./hope-ui-design-system/helper/functions";
@import "./hope-ui-design-system/helper/mixins";
@import "./hope-ui-design-system/helper/reboot";

@import "./dark/index";

// Custom Styles
:root {
  --bs-primary: #427EEB;
  --bs-link-color: #427EEB;
  --bs-btn-bg: red;
}

.btn-primary {
  --bs-btn-bg: #427EEB;
  --bs-btn-border-color: #427EEB;
}

body {
  font-family: 'Poppins', sans-serif;
}


.dark .flatpickr-months .flatpickr-prev-month > svg {
  fill: white;
}
