.rti--container {
  border-color: #EEEEEE;
  --rti-bg: "#fff";
  --rti-border: rgba(255, 255, 255, 0.1);
  --rti-tag-remove: "#e53e3e";
  border-radius: 0.25rem;
}

.dark .rti--container {
  border-color: rgba(255, 255, 255, 0.1);
  --rti-bg: "#fff";
  --rti-border: rgba(255, 255, 255, 0.1);
  --rti-tag-remove: "#e53e3e";
  border-radius: 0.25rem;
}

.invalid .rti--container {
  border-color: #c03221;
}

.rti--input {
  background-color: #FFFFFF;
  color: #8A92A6;
  padding-left: 8px;
}

.dark .rti--input {
  background-color: #1E2746;
  color: #FFFFFF
}

.rti--tag {
  border: 1px solid #427EEB !important;
  background: #427EEB !important;
  color: #FFFFFF !important;
}