@import "./tootltip";
@import "./colour-picker.scss";
@import "scrollbar";

small.platform-name {
  margin-right: 15px
}

button.no-pointer {
  cursor: auto !important;
}

.tooltip-normal {
  opacity: 1;
  cursor: pointer;
}

.list-campaign-action {
  .opacity-10 {
    opacity: 1;
  }

  .opacity-8 {
    opacity: 0.8;
  }

  .opacity-6 {
    opacity: 0.6;
  }

  .opacity-4 {
    opacity: 0.4;
  }
}

.landing-page-image-container {
  display: flex;
  align-items: flex-end;

  img {
    max-height: 74px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.25rem;
    max-width: 200px;
    margin-bottom: 17px;
  }
}

.landing-page-video-container {
  display: flex;
  align-items: flex-end;
  //position: relative;
  //padding-top: 150px;
  //overflow: hidden;

  video {
    max-height: 74px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.25rem;
    max-width: 200px;
    margin-bottom: 17px;
    //position: absolute;
    //top: 0;
    //left: 0;
    //width: 100%;
    //object-fit: cover;
  }
}

.__floater {
  z-index: 2000 !important;
}

.media-buying {
  flex: none !important;
  width: 200px !important;
}

.pl-10 {
  padding-left: 10px;
  padding-top: 3px;
}

.hidden-input {
  width: 0px;
  opacity: 0;
}

.loader-container {
  position: relative;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.inactive {
  opacity: 0.3;
  pointer-events: none;
}

.custom-toast {
  font-size: 18px;
}

.custom-toast div {
  text-align: left;
  padding: 5px 0;
}

/* Apply 400px width only for devices wider than 768px (tablets and desktops) */
@media (min-width: 768px) {
  .custom-toast {
    width: 400px;
    margin-left: -40px;
  }
}